<template>
 <div>
          <Exchange/>
          <Pair/>
          <Trades/>
  </div>
</template>

<script>
//Golden layout

import Exchange from "@/components/cctx/Exchange";
import Trades from "@/components/cctx/Trades";
import Pair from "@/components/cctx/Pair";
export default {
  name: "cctx_test",
  components: {
    Exchange,
    Trades,
    Pair
  }
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Staatliches");

html,
body {
  margin: 0px;
  padding: 0px;
  height: 100%;
}

#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  text-align: center;
  min-height: 100%;
  display: grid;
  grid-template-columns: auto auto auto;
}

.titleApp {
  font-size: 45px;
  font-family: "Staatliches", cursive;
}
</style>
